.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.header {
  width: 100%;
  height: 100vh;
  background-image: url(./Images/new13-min-min.png);
  background-position: 50%;
  background-repeat: no-repeat;
  overflow: hidden;
  -o-object-fit: contain;
  object-fit: contain;
  position: relative;
  background-size: cover;
  margin: 0 auto;
}

.header-order {
  width: 100%;
  height: 100vh;
  background-image: url(./Images/new14-min-min.png);
  background-position: 50%;
  background-repeat: no-repeat;
  overflow: hidden;
  -o-object-fit: contain;
  object-fit: contain;
  position: relative;
  background-size: cover;
  margin: 0 auto;
  display: flex;
}

.Button_btn_header:hover {
  border-bottom: 2px solid #717171; /* Adjust color as needed */
  padding-bottom: 8px; /* Adjust padding as needed */
}

.Header_itemContainer {
  width: 93%;
  margin: 0;
  padding: 0 47px;
  display: flex;
  justify-content: space-between;
}

.Header_container {
  width: 100%;
  height: 64px;
  background-color: transparent;
  z-index: 100;
  transition: all .3s ease-out;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: fixed;
}

.Header_itemContainer>div {
  display: flex;
  gap: 50px;
}

.Header_scrollColor {
  background: rgba(0, 0, 0, .3) !important;
  /*-webkit-backdrop-filter: blur(20.6333px) !important;*/
  /*backdrop-filter: blur(20.6333px) !important;*/
}

.Button_btn {
  cursor: pointer;
  border: none;
  background: none;
  padding: 0;
  font-size: 14px;
  font-weight: 500;
  color: #FFF;
  height: inherit;
  width: inherit;
}

main {
  overflow: hidden;
}

#advancedScooter_most-advanced-scooter {
  max-width: 1366px;
  margin: 60px auto 66px;
}

.advancedScooter_container {
  display: flex;
  flex-direction: column;
  max-width: 960px;
  margin: 0 auto;
}

.advancedScooter_labelContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 24px;
}

.advancedScooter_buttonDesktop {
  display: block;
}

.advancedScooter_buttonsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.advancedScooter_buttonMainNew {
  font-family: Gentona Medium;
  font-weight: 400;
  font-size: 14px;
  width: 158px;
  height: 53px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.advancedScooter_buttonMain {
  position: relative;
  background: #000;
  margin-left: 12px;
  overflow: hidden;
  outline: none;
  color: #fff;
  box-shadow: 0 8px 32px rgba(#000, .2);
  transition: all .2s ease;
}

.Button_btn_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.advancedScooter_advancedScooterCard {
  display: flex;
  flex-direction: column;
}

.advancedScooter_cardDescContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.advancedScooter_headWrapper {
  display: flex;
  align-items: center;
}

.advancedScooter_cardDescContainer .advancedScooter_text {
  width: 258px;
  margin-top: 12px;
}

.advancedScooter_subtextContainer {
  padding-top: 8px;
}

.advancedScooter_badgeText {
  background: rgba(191, 72, 0, .12);
  color: #bf4800;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-left: 8px;
  padding: 1px 6px;
}

.advancedScooter_cardDescContainer .advancedScooter_text {
  width: 258px;
  margin-top: 12px;
}



.advancedScooter_cardDescContainer p {
  margin: 0;
}

.advancedScooter_bigFontCta {
  font-size: 24px !important;
  font-style: italic;
}

.Button_btn_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

.Button_d_24 {
  font-size: 24px;
}

.Button_btn {
  cursor: pointer;
  border: none;
  background: none;
  padding: 0;
  font-size: 14px;
  font-weight: 500;
  color: green;
  height: inherit;
  width: inherit;
}

.Button_btn {
  cursor: pointer;
  border: none;
  background: none;
  padding: 0;
  font-size: 14px;
  font-weight: 500;
  color: green;
  height: inherit;
  width: inherit;
}

.advancedScooter_cardContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 24px;
  position: relative;
}


.attribute-container-child {
  overflow-x: hidden;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  padding-right: 20px;
}

@media screen and (min-width: 1024px) and (max-height: 1310px) {

  /* Laptop */
  .right-container {
    width: 400px;
    height: 100vh;
    position: absolute;
    /*background: #fff;*/
    top: 0px;
    right: 0px;
    height: 100%;
    min-height: auto;
    overflow: hidden;
  }

  .right-container .top {
    width: 100%;
    height: 40%;
    /*margin-top: 7vh;*/
  }

  .right-container .middle {
    width: 100%;
    height: 40%;
    margin-top: 2vh;
  }

  .right-container .bottom {
    width: 100%;
    height: 20%;
  }

  .right-container .top {
    /*background-color: #f0f0f0;  !* For visualization purposes *!*/
  }

  .right-container .middle {
    /*background-color: #bdc3c7;  !* For visualization purposes *!*/
  }

  .right-container .bottom {
    /*background-color: #16aa51;  !* For visualization purposes *!*/
  }

  .attribute-container-child {
    overflow-x: hidden;
    height: 80%;
    width: 100%;
    overflow-y: auto;
    padding-right: 20px;
  }
}

.cv-desktop-color-name-wrapper {
  text-align: left;
  display: flex;
  gap: 5px;
  /*margin-top: 20px;*/
}

.cv-desktop-color-name-wrapper .cv-desktop-color {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #999;
  text-transform: uppercase;
}

.cv-desktop-color-name-wrapper .cv-desktop-color {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #999;
  text-transform: uppercase;
}

.cv-desktop-color-name-wrapper .cv-desktop-color-name {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #999;
}

.colour-container-np {
  margin-top: 0px !important;
  gap: 10px 24px;
  justify-content: flex-start;
}

.colour-np {
  margin-top: 16px;
  padding-top: 0px;
}

.attribute-container {
  padding: 0 24px 0 24px;
  max-width: 375px;
  width: 100%;
  height: 100%;
  padding-left: 10px;
}



.each-colour-active-border-np {
  border: 2.4px solid #92c83e;
  width: 54px;
  height: 54px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.each-colour-non-active-border-np {
  border: 2.4px solid transparent;
  width: 54px;
  height: 54px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.each-colour-active-np,
.each-colour-np {
  width: 40.8px;
  height: 40.8px;
}

.each-colour-active-np {
  width: 48px;
  height: 48px;
  cursor: pointer;
  border-radius: 30px;
  position: relative;
}

.slick-prev::before {
  content: "";
  position: absolute;
  background: url(https://olaelectricvideos.blob.core.windows.net/olaelectric-videos/configs-static/assets/newPurchase/banner-left-arrow.svg) top no-repeat;
  opacity: 1;
  height: 50px;
  width: 40px;
  left: 0;
  top: 0;
}

.slick-next::before {
  content: "";
  position: absolute;
  background: url(https://olaelectricvideos.blob.core.windows.net/olaelectric-videos/configs-static/assets/newPurchase/banner-right-arrow.svg) top no-repeat;
  opacity: 1;
  height: 50px;
  width: 40px;
  right: 0;
  top: 0;
}

.footer-mweb-bottom {
  height: 86px;
  display: flex;
  padding: 16px;
  align-items: center;
}

.new-price-breakup-wrapper {
  text-align: left;
  flex: 1;
  width: 100%;
}

.new-price-breakup-wrapper .npb-fromat-amount {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: white;
  display: flex;
  align-items: center;
  gap: 5px;
}

.new-price-breakup-wrapper .npb-fromat-amount-vip {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: white;
  display: flex;
  align-items: center;
  gap: 5px;
  text-decoration: line-through; /* Add the crossed-out effect */
  position: relative; /* To position the new price */
  margin-bottom: 20px; /* Provide more space at the bottom for the new price */
}

.npb-new-price {
  color: white;
  font-weight: 500;
  font-size: 15px;
  position: absolute; /* Absolute positioning to overlap the original price. */
  bottom: -21px; /* Position the new price below the original price. Adjust based on the space you want between them. */
  display: flex; /* Make it a flex container */
  align-items: center; /* Vertically align the price and the discount */
  gap: 5px; /* Spacing between the price and discount, adjust as needed */
}

.vip-discount {
  color: #ffd700; /* Goldish color for VIP, adjust as needed */
  /*padding: 2px 5px; !* Some padding for better appearance *!*/
  font-size: 12px; /* Adjust as needed */
  /*border-radius: 2px; !* Rounded corners for better aesthetics *!*/
}

.new-price-breakup-wrapper .npb-price-tag {
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  color: rgba(0, 0, 0, .56);
  text-decoration-line: underline;
}

.npb-format {
  font-size: 10px;
  font-weight: 500;
  color: white;
}

.footer-mweb-container-container .footer-mweb-container {
  margin: auto;
}

.btn-reserve {
  height: 48px;
  background: #000;
  font-size: 14px;
  opacity: 1;
  line-height: 24px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 76px;
  cursor: pointer;
  margin: 0 auto;
}

@media (min-width: 450px) {
  .footer-mweb-container {
    width: 204px;
  }
}

.footer-mweb-container-container {
  position: fixed;
  width: 400px;
  max-width: 100vw;
  right: 0;
  bottom: 0;
  display: initial;
  min-height: 86px;
  /*background: #fff;*/
  /*box-shadow: 0px 0px 1px rgb(0 0 0 / 24%), 0px 1px 3px rgb(0 0 0 / 12%);*/
}

.Header_itemContainer>div>div button {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 17px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 4px 8px;
}

.Button_btn_header {
  cursor: pointer;
  border: none;
  background: none;
  padding: 0;
  font-size: 14px;
  font-weight: 500;
  color: #FFF;
  height: inherit;
  width: inherit;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

body {
  background: #bdc3c7;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #2c3e50, #bdc3c7);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #2c3e50, #bdc3c7);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.form {
  display: flex;
  height: 100%;
  justify-content: flex-end;
}

.form1 {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 5%;
  right: 0;
}

.form-auth {
  background-color: #white;
  width: 400px;
  padding: 20px;
  height: 100%;
}

.form-auth1 {
  background-color: rgb(150, 150, 150);
  width: 200px;
  padding: 20px;
  height: 100%;
}

.form-order {
  position: absolute;
  left: 60%;
  top: 60%;
  width: 30%;
  margin: 20px auto;
  padding: 20px;
  display: flex;
  gap: 20%;
}

.form-body {
  text-align: left;
  padding: 20px 10px;
  height: 70%;
  width: 100%;
  overflow-y: auto;
  position: relative;
  margin-top: 50px;
}

.form-body-reg {
  text-align: left;
  padding: 20px 10px;
  height: 100%;
  width: 100%;
  overflow-y: hidden;
}

.form-body-child {
  height: 350px;
  overflow-y: auto;
}

.form-body>* {
  padding: 5px;
}

.form-body-child>* {
  padding: 5px;
}

.form__label {
  width: 40%;
}

.form__input {
  width: 50%;
  height: 30px;
  outline: 0;
  border-width: 0 0 2px;
  border-color: gray;
}

.form__shippingAddress {
  width: 50%;
  height: 70px;
  outline: 0;
  border-width: 0 0 2px;
  border-color: gray;
}

.footer {
  text-align: center;
  display: flex;
}

.btn {
  font-size: 18px;
  border: none;
  color: #fff;
  width: 100%;
  cursor: pointer;
  height: 50px;
  background: #000;
}

.btn-style {
  left: 42%;
  position: absolute;
  width: 40%;
  height: 30px;
  background-color: #000;
  color: #FFF;
  cursor: pointer;
}

.btn-style1 {
  width: 40%;
  height: 40px;
  background-color: #000;
  color: #FFF;
  cursor: pointer;
  font-size: 15px;
  font-weight: 700;
}

.errorMsg {
  color: #cc0000;
  margin-bottom: 12px;
}

/* Close button */
.close {
  color: #fff;
  background-color: #999;
  position: absolute !important;
  height: 20px;
  width: 20px;
  top: -8px;
  right: -8px;
  font-size: 20px;
  border-radius: 50%;
  border: 2px solid #333;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  outline: none;
}

.closeMark {
  position: absolute;
  top: -1px;
  left: 2px;
}

.model-box-container-np {
  margin-top: 24px;
  border-top: 0.5px solid rgba(0, 0, 0, .1);
}

.model-box-container-np {
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  width: 95%;
  gap: 5%;
}

.model-box-container-np .model-selection-box-np {
  margin-top: 24px;
}

.model-selection-box-border-np {
  background-color: #fff !important;
  border: 1.5px solid #16aa51 !important;
}

.model-selection-box-np {
  margin-top: 12px;
  background: #fafafa;
  border: 2px solid rgba(0, 0, 0, .1);
  cursor: pointer;
  background: #f9f9f9;
  border: 0.5px solid rgba(0, 0, 0, .1);
  flex: 1;
}

.model-title-and-info {
  display: flex;
  position: relative;
  justify-content: space-between;
}

.model-box-container-np .new-s1-lable-for-mobile {
  top: 6px;
  left: 12px;
  width: fit-content;
  background: none;
}

.model-box-container-np .new-s1-lable {
  position: absolute;
  right: 16px;
  top: 16px;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  width: 38px;
  height: 20px;
  background: rgba(191, 72, 0, .12);
  color: #bf4800;
  display: flex;
  align-items: center;
  justify-content: center;
}

.range-speed-np-for-mobile {
  flex-direction: column;
  padding-left: 12px;
  padding-top: 16px;
  padding-right: 0;
  padding-bottom: 0;
}

.model-title-np {
  font-size: 16px;
  line-height: 28px;
  text-align: left;
  padding-left: 16px;
  padding-top: 16px;
  color: #000;
}

.model-title-np-mobile {
  padding-left: 12px;
  padding-top: 40px;
}

.range-speed-np {
  display: flex;
  text-align: left;
  padding-top: 22px;
  padding-bottom: 16px;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
}

.range-np-for-mobile {
  margin-bottom: 12px;
}

.range-np-for-mobile {
  margin-bottom: 12px;
}

.name-np-for-mobile {
  font-size: 10px !important;
}

.val-np-for-mobile {
  font-size: 12px;
}

.name-np {

  font-size: 14px;
  line-height: 16px;
  color: gray;
}

.name-np-for-mobile {
  font-size: 10px !important;
}

.val-np {

  font-size: 14px;
  line-height: 20px;
  color: #000;
}

.small-val-np {
  font-size: 12px;
}

.estimated-dispatch-container-for-mobile {
  margin-top: 13px;
  display: flex;
  text-align: center;
  justify-content: center;
}

.estimated-dispatch-container {
  background: rgba(22, 170, 81, 0.4);
  min-height: 32px;
  color: #e8f7ee;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  font-weight: 400;
}

.three-attributes-container {
  display: flex;
  position: fixed;
  bottom: 6.5%;
  width: 40%;
  justify-content: space-between;
  left: 10%;
}

.val-att-np {
  font-size: 31.3875px;
  line-height: 28px;
  color: #fff;
}

.name-att-np {
  font-family: "Gentona Light";
  font-size: 17.4375px;
  line-height: 28px;
  text-align: center;
  color: #fff;
  opacity: .8;
}

.privacy-checkbox-wrapper {
  height: 72px;
  width: 100%;
  background: #f1f3f5;
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
}

.privacy-checkbox-wrapper {
  height: 72px;
  width: 100%;
  background: #f1f3f5;
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
}

.privacy-checkbox-wrapper .privacy-checkbox {
  background: #fff;
  border: 2px solid #cbced7;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.privacy-checkbox-wrapper .privacy-text {

  color: rgba(0, 0, 0, .56);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
}

.privacy-checkbox-wrapper .privacy-checkbox-checked {
  border: 2px solid #087a36;
  background: #16aa51;
}

.accessories {
  /*margin-top: 24px;*/
  /*border-top: 0.5px solid rgba(0, 0, 0, .1);*/
  overflow-y: auto;
  max-height: 112%;  /* Adjust this value as per your requirement */
}

.accessory-card-wrapper {
  height: 148px;
  background: #fff;
  border: 1px solid #0000001a;
  display: flex;
  text-align: left;
  flex-direction: row-reverse;
  margin-top: 15px;
}

.accessory-card-wrapper .accessory-card-details {
  flex: 1;
}

.accessory-card-wrapper .accessory-card-details .accessory-card-details-inner {
  margin-left: 8px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  min-height: var(--accessory-card-details-inner-height);
}

.accessory-card-wrapper .accessory-card-details .accessory-card-details-inner .accessory-card-item-name {
  font-weight: 500;
  font-size: 14px;
  color: #000;
  margin-bottom: 8px;
}

.accessory-card-wrapper .accessory-card-details .accessory-card-details-inner .accessory-card-item-price {
  font-weight: 400;
  font-size: 15px;
  color: #000;
}

.accessory-card-wrapper .accessory-card-details .accessory-card-details-inner .accessory-card-dispatch-details {
  font-weight: 400;
  font-size: 11px;
  color: #717171;
  mix-blend-mode: normal;
}

.accessory-card-wrapper .accessory-card-details .accessory-card-details-inner .accessory-card-cta-wrapper {
  flex: 1;
  height: 100%;
  width: 88px;
  margin-top: 15px;
}

.accessory-card-wrapper .accessory-card-details .accessory-card-details-inner .accessory-card-cta-wrapper .accessory-card-action-cta {
  cursor: pointer;
  min-width: 79px;
  height: 28px;
  background: #fff;
  border: 0.5px solid rgba(0, 0, 0, .1);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 8px;
  font-weight: 500;
  font-size: 14px;
}

.accessory-card-wrapper .accessory-card-view-n-cta {
  width: 144px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.accessory-card-wrapper .accessory-card-view-n-cta .accessory-card-item-image {
  height: 100%;
}

.accessory-card-wrapper .accessory-card-view-n-cta .accessory-card-item-image>img {
  height: 100% !important;
  width: 100%;
  object-fit: cover;
}

.estimated {
  /*margin-top: 24px;*/
  border-top: 0.5px solid rgba(0, 0, 0, .1);
}

.explore-accessories-heading {
  font-weight: 500;
  font-size: 20px;
  color: white;
  text-align: left;
  margin-bottom: 16px;
  margin-left: unset;
}

.input-box-container {
  position: relative;
  display: flex;
  flex-direction: column;
}

.input-box-container input {
  border: 1.5px solid #e0e0e0;
  padding: 20px 20px 8px 16px;
  outline: none;
  box-shadow: none;
  background: #f3f4f5;
  transition: .2s cubic-bezier(0, 0, .2, 1) 0ms;
  font-family: Gentona;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, .86);
}

.input-box-container label {
  position: absolute;
  pointer-events: none;
  height: 100%;
  transform: translateY(35%) scale(1);
  transform-origin: top left;
  transition: .2s cubic-bezier(0, 0, .2, 1) 0ms;
  left: 16px;
  font-family: Gentona;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 16px;
  color: rgba(0, 0, 0, .36);
}

label.active {
  transform: translateY(10%);
  transition: .5s;
  color: #32c86e;
  font-size: 12px;
}

input.active {
  border: 1px solid #32c86e;
}

.login-page-forget-password {
  color: gray;
  font-size: 15px;
  font-weight: 500;
}

.sign-up-text {
  color: #16aa51;
  font-size: 15px;
  font-weight: 500;

}

.celebration-text {
  display: flex;
  justify-content: flex-start;
  position: absolute;
  margin-top: 30px;
  padding: 20px;
  color: white;
}

.stock-text {
  padding: 5px;
  font-weight: 500;
  font-size: 13px;
}

.myorder-container {
  display: flex;
  justify-content: space-between;
  padding: 0 24px 0 24px;
}

.myorder-container-child {
  background-color: #fff;
  height: 60%;
  margin-top: 80px;
  border-radius: 10px;
  display: grid;
  gap: 10%;
  border: 3px solid #dbdfe5;
  padding: 10px;
}

.myorder-container-child1 {
  background-color: rgb(243, 243, 243);
  height: 60%;
  width: 80%;
  margin-top: 80px;
  border-radius: 10px;
  display: grid;
  gap: 10%;
  border: 3px solid #dbdfe5;
  padding: 10px;
}

.flex-container {
  width: 80%;
  height: 70%;
  margin: 0 auto;
}

.flex-container .column {
  padding: 8px;
  font-size: 14px;
}

.bg-alt {
  font-size: 10px;
  font-weight: 700;
}

.btn-style2 {
  width: 15%;
  height: 40px;
  background-color: #000;
  color: #FFF;
  cursor: pointer;
  font-size: 15px;
  font-weight: 700;
}

.close-button-container {
  display: flex;
  justify-content: end;
  padding: 0 24px 0 24px;
}

.delete-img {
  height: 30px;
  width: 30px;
  padding-top: 10px;
}

.delete-text {
  padding-top: 20px;
  font-size: 14px;
  font-weight: 700;
}

.tooltip {
  position: relative;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 150px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: 60px;
  right: 50%;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.spinner-container {
  position: absolute;
  z-index: 1000;
  height: 100%;
  width: 100%;
  background-color: rgb(244 243 230 / 70%);
}

.loading-text {
  position: absolute;
  right: 50%;
  top: 60%;
}

.loading-spinner {
  position: absolute;
  right: 50%;
  top: 50%;
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3;
  border-top: 10px solid #383636;
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

.test-container {
  display: flex;
  flex-direction: column;
  padding: 0 24px 0 24px;
}

.location-container {
  display: flex;
  justify-content: space-between;
  padding: 0 24px 0 24px;
}

.location {
  margin-right: 10px;
  display: flex;
  gap: 10%;
}

.location-name {
  font-weight: 500;
  cursor: pointer;
}

.location-list {
  display: flex;
  flex-flow: row wrap;
  margin-left: -10px;
  margin-right: -10px;
  gap: 10%;
  overflow: auto;
  padding-left: 10px;
  padding-right: 10px;
}

.service-location-container {
  width: 20%;
  height: 50%;
  background-color: rgb(255, 255, 255);
  pointer-events: all;
  text-align: center;
  border-radius: 5px;
  border: 2px solid transparent;
  cursor: pointer;
  padding: 16px 20px;
}
.disabledbutton {
  pointer-events: none;
  opacity: 0.4;
}
.service-location-container.active {
  border: 2px solid rgb(255, 220, 72);
}

.service-location-container-child {
  display: flex;
  justify-content: space-between;
}

.location-name {
  font-size: 20px;
  line-height: 24px;
}

.addr {
  font-size: 12px;
  line-height: 16px;
}

.gigyaContainerStyle {
  position: absolute;
  top: 53%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.93);
  padding: 10px;
  border: 3px solid #333;
  border-radius: 20px;
}

.warranty-checkbox-wrapper {
  height: 72px;
  width: 98%;
  /*background: #f1f3f5;*/
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  /*border: 1px solid #f3f3f3;*/
}

.warranty-checkbox-wrapper .warranty-checkbox {
  background: #fff;
  border: 2px solid #cbced7;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 5px;
}

.warranty-checkbox-wrapper .warranty-text {

  color: rgba(0, 0, 0, .56);
  font-weight: 500;
  color: white;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
}

.warranty-checkbox-wrapper .warranty-checkbox-checked {
  border: 2px solid #087a36;
  background: #16aa51;
}

.warranty-checkbox-wrapper .warranty-text .warranty-price {

  color: rgba(0, 0, 0, .56);
  font-weight: 300;
  color: white;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
}

.accessories::-webkit-scrollbar {
  width: 10px; /* Adjust width for vertical scrollbars */
}

.accessories::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.accessories::-webkit-scrollbar-thumb {
  background: #888;
}

.accessories::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.landing-page-card {
  width: 45%;
  min-height: 180px;
  background: rgba(38, 38, 38, 0.4);
  border-radius: 10px;
  border: 1px solid #3F3F40;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -135%);
}
