/* Assuming the parent of .buttonList is .parentContainer */
.parentContainer {
    display: flex;
    flex-direction: column;
    justify-content: center; /* To center vertically */
    align-items: center;     /* To center horizontally */
    height: 140%;  /* Adjust according to your requirements */
}

.buttonList {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.buttonItem {
    cursor: pointer;
    padding: 10px 20px;
    color: #717171;
    position: relative;
    display: inline-block;
    font-family: 'Arial', sans-serif;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: -0.75px;
    transition: color 0.3s ease;
}

.textWrapper {
    position: relative;
    display: inline-block;
}

.buttonItem.active {
    color: white;
    font-weight: bold;
}

.buttonItem:hover {
    color: white;
}

.buttonItem.active .textWrapper::before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 15px;
    height: 2px;
    background-color: firebrick;
    margin-left: -25px;
}

.stepWrapper {
    display: flex;
    justify-content: center; /* Horizontally centers items */
    align-items: center;     /* Vertically centers items */
    gap: 10px;               /* Adds some space between the text and the button */
    height: 65%;            /* Assuming you want to center it in the entire height of its parent. Adjust as needed. */
}

.nextStepText {
    font-size: 15px;
    font-family: 'Arial', sans-serif;
    color: white;
    text-transform: uppercase;
    letter-spacing: -1.5px;
    transition: color 0.3s ease;
    font-weight: bold;
    padding-right: 10px;
    white-space: nowrap;
}

.ovalButton {
    background-color: transparent; /* Adjust color as needed */
    border: 2px solid red;         /* Red border */
    color: white;
    padding: 8px 35px;
    border-radius: 30px; /* Makes the button oval */
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.ovalButton:hover {
    background-color: #555; /* Darker shade for hover effect */
}

